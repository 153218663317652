import React from 'react'
import {Link, graphql, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import Helmet from 'react-helmet'
// import Layout from '../../components/Layout'
import SecondaryLayout from '../../components/Layout/secondaryLayout'
import {TimelineMax} from "gsap";
const play = require('../../assets/icons/play.svg')
const honeymoon = require('../../assets/images/honeymoon-domestic.jpg')
const pilgrimage = require('../../assets/images/pilgrimage-domestic.jpg')
const family = require('../../assets/images/family-tour-domestic.jpg')


// import { rhythm } from '../utils/typography'


class Domestic extends React.Component {
    constructor(props) {
        super(props);
        this.tween = new TimelineMax({repeat: -1, paused: true});
        this.images = React.createRef();
    }

    componentDidMount(){
        const count = this.images.current.children.length - 1;
        for (let i = 0; i <= count; i++) {
            this.tween
                .add( TweenMax.fromTo(this.images.current.children[i], 1, {opacity: 1},{opacity: 0}) ); //0ms 500ms
            if(i !== count){
                this.tween.add( TweenMax.fromTo(this.images.current.children[i+1], 1, {opacity: 0},{opacity: 1}), '-=.7') //0ms 500ms
                    .add(function(){} , '+=3' )
            }else{
                this.tween.add( TweenMax.fromTo(this.images.current.children[0], 1, {opacity: 0},{opacity: 1}), '-=.7') //0ms 500ms
                    .add(function(){} , '+=3' )
            }
        }

        this.tween.play();
    }

    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const images = get(this, 'props.data.images')
        const siteDescription = get(
            this,
            'props.data.site.siteMetadata.description'
        )
        return (
            <SecondaryLayout location={this.props.location}>
                <Helmet
                    htmlAttributes={{lang: 'en'}}
                    meta={[{name: 'description', content: siteDescription}]}
                    title={'Domestic Travel Packages | ' + siteTitle}
                />
                <section className="hero is-info is-fullheight is-bold packageOverview">
                    <div className="background">
                        <div className="images" ref={this.images}>
                            {
                                images.edges.map((image, index)=>
                                    <Img key={index}
                                         fluid={image.node.childImageSharp.fluid}
                                    />
                                )
                            }
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: "100%",
                                height: "100%",
                                // background: "rgba(255, 255, 255, 0.4)"
                                background: "linear-gradient(40deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.78) 50%)"
                            }}
                        />
                    </div>
                    <div className="hero-body">
                        <div className="columns">
                            <div className="column is-half is-offset-two-fifths">
                                <h1 className="title text-montserrat">
                                    TRAVEL INDIA
                                </h1>
                                <h2 className="subtitle">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aut beatae dolor
                                    eligendi enim hic impedit, libero magni minima modi perferendis
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="hero-foot has-padding-md">
                        <div className="tile is-ancestor is-8 is-pulled-right">
                            <div className="tile is-parent">
                                <Link className="tile is-child notification is-info has-padding-bottom-xxl"
                                     style={{background: `url(${honeymoon}) center no-repeat`}} to="/domestic/honeymoon">
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(0, 0, 0, 0.2)",
                                            zIndex: 1
                                        }}
                                    />
                                    <p className="subtitle">Looking for</p>
                                    <p className="title is-size-4 is-uppercase">Honeymoon Packages</p>
                                    <span className="icon is-medium is-pulled-right">
                                        <img src={play} alt=""/>
                                    </span>
                                </Link>
                            </div>
                            <div className="tile is-parent">
                                <Link className="tile is-child notification is-success has-padding-bottom-xxl"
                                      style={{background: `url(${pilgrimage}) center no-repeat`}} to="/domestic/pilgrimage">
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(0, 0, 0, 0.2)",
                                            zIndex: 1
                                        }}
                                    />
                                    <p className="subtitle">Looking for</p>
                                    <p className="title is-size-4 is-uppercase">Pilgrimage Tours</p>
                                    <span className="icon is-medium is-pulled-right">
                                        <img src={play} alt=""/>
                                    </span>
                                </Link>
                            </div>
                            <div className="tile is-parent">
                                <Link className="tile is-child notification is-success has-padding-bottom-xxl"
                                      style={{background: `url(${family}) center no-repeat`}} to="/domestic/family">
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                            background: "rgba(0, 0, 0, 0.2)",
                                            zIndex: 1
                                        }}
                                    />
                                    <p className="subtitle">Looking for</p>
                                    <p className="title is-size-4">School/Family Packages</p>
                                    <span className="icon is-medium is-pulled-right">
                                        <img src={play} alt=""/>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </SecondaryLayout>
        )
    }
}

export default Domestic

export const pageQuery = graphql`
  query {
  site {
      siteMetadata {
        title
        description
      }
    }
  images: allFile(filter: { absolutePath: { regex: "assets/packages/domestic/" },sourceInstanceName: { eq: "assets" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
}
`
